<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Estefania Villada Chavarria                                   ###### -->
<!-- ###### @date: Abril 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <div class="contenido">
        <section class="d-flex">
            <!-- Filtrar por proceso -->
            <v-autocomplete class="me-2 filtro" v-model="buscar.proceso" label="Proceso" outlined dense hide-details
                :items="obtenerProcesos" :menu-props="{ offsetY: true, maxHeight: 150 }" no-data-text="No se encontraron resultados.">
            </v-autocomplete>

            <!-- Filtrar por título -->
            <v-text-field class="me-2 filtro" v-model="buscar.titulo" label="Título" outlined dense hide-details></v-text-field>
            
            <!-- Este botón abre un diálogo para parametrizar un tablero -->
            <div>
                <v-tooltip left color="success" :open-on-focus="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small fab color="success" v-bind="attrs" v-on="on" @mousedown.prevent="dialogoTablero = true">
                            <v-icon> add </v-icon>
                        </v-btn>
                    </template>
                    <span>Agregar</span>
                </v-tooltip>
            </div>
        </section>

        <!-- Diálogo para crear/editar la información de un tablero -->
        <v-dialog v-model="dialogoTablero" transition="dialog-bottom-transition" max-width="40rem" :editar="false" persistent>
            <v-card>
                <v-card-title class="encabezado">
                    <span class="text-h6"> {{ tituloDialogo }} </span>
                </v-card-title>
                <v-card-text class="pt-5">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <v-form lazy-validation>
                            <div class="d-flex">

                                <!-- Campo para seleccionar el proceso -->
                                <validation-provider class="campo " v-slot="{ errors }" :rules="{ required: true }">
                                    <v-autocomplete v-model="crearTablero.proceso" label="Proceso" dense outlined
                                        :error-messages="errors" :items="obtenerProcesos" :menu-props="{ offsetY: true, maxHeight: 180 }"
                                        no-data-text="No se encontraron resultados." :disabled="editar" @input="validarExistencia()">
                                    </v-autocomplete>
                                </validation-provider>

                                 <!-- Campo para ingresar el título -->
                                 <validation-provider class="campo ms-2" v-slot="{ errors }" :rules="{ required: true }">
                                    <v-text-field v-model="crearTablero.tituloTablero" label="Título" required dense outlined
                                        :error-messages="validarTablero || errors" @input="validarExistencia()" @keypress="validarCaracteres" @paste.prevent>
                                    </v-text-field>
                                </validation-provider>
                            </div>
                            
                            <div class="d-flex">
                                <!-- Campo para ingresar la descripción -->
                                <validation-provider class="campo" v-slot="{ errors }" :rules="{ required: true }">
                                    <v-text-field v-model="crearTablero.descripcionTablero" label="Descripción" required dense outlined 
                                        :error-messages="errors">
                                    </v-text-field>
                                </validation-provider>
                            </div>

                            <!-- Campo para ingresar la url -->
                            <validation-provider class="campo " v-slot="{ errors }" :rules="{ required: true, url: true }" ref="urlValidation">
                                <v-text-field v-model="crearTablero.urlTablero" label="URL" :error-messages="errors" dense outlined>
                                </v-text-field>
                            </validation-provider>

                            <!-- Botones -->
                            <div class="d-flex justify-end">
                                <v-btn class="me-2" text color="error" @click="clear()">Cerrar</v-btn>
                                <v-btn color="success" :disabled="invalid || botonDisabled" text depressed
                                    @click="!editar ? agregarTablero() : editarTablero()">Guardar
                                </v-btn>
                            </div>

                        </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Tabla de los tableros parametrizados -->
        <v-data-table class="elevation mt-4" :items="tablaTableros.items" :loading="tablaTableros.loading" fixed-header
            :headers="tablaTableros.headers" :footer-props="tablaTableros.footerProps" :page.sync="tablaTableros.page"
            :items-per-page.sync="tablaTableros.itemsPerPage" :server-items-length="tablaTableros.totalPage" height="64vh">
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" v-bind:key="item.idTablero">
                        <td> {{ obtenerNombreProceso(item.proceso) }} </td>
                        <td> {{ item.tituloTablero }} </td>
                        <td> {{ item.descripcionTablero }} </td>
                        <td class="text-truncate" style="max-width: 16rem;">
                            {{ item.urlTablero }}
                        </td>
                        <td>
                            <v-icon :color="item.estado === false ? 'success' : 'error'">
                                {{ item.estado === false ? 'check_circle' : 'cancel' }}
                            </v-icon>
                            {{ item.estado === false ? 'Activo' : 'Inactivo' }}
                        </td>
                        <td class="text-center">
                            <v-tooltip bottom :color="item.estado === false ?'orange' : 'gray'">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon @click="item.estado === false && abrirDialogoEditar(item)">
                                        <v-icon medium :color="item.estado === false ?'orange' : 'gray'">
                                            border_color
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ item.estado === false ? 'Editar' : 'Tablero inactivo' }}</span>
                            </v-tooltip>

                            <v-tooltip bottom :color="item.estado === false ?'red' : 'green'">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon @click="abrirDialogoEstado(item)">
                                        <v-icon medium :color="item.estado === false ? 'red' : 'green'">
                                            {{ item.estado === false ? 'person_add_disabled' : 'how_to_reg' }}
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ item.estado === false ? 'Inactivar' : 'Activar' }}</span>
                            </v-tooltip>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:footer.page-text="items">
                {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
        </v-data-table>

        <!-- dialogo para cambiar el estado -->
        <v-dialog v-model="dialogoCambiarEstado" transition="dialog-bottom-transition" max-width="23rem" persistent>
            <v-card>
                <v-card-title class="encabezado">
                    <span class="text-h6">
                        {{ mensajeEstado }}
                    </span>
                </v-card-title>
                <v-card-text class="pt-5">
                    <div class="d-flex justify-end">
                        <v-btn class="me-2" color="error" text @click="dialogoCambiarEstado = false">No</v-btn>
                        <v-btn color="success" depressed text @click="cambiarEstado()">Si</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import { mapMutations, mapState } from "vuex";
import { Role } from "../../../../router/role.js";
import { required } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';

// validaciones con vee-validate
setInteractionMode('eager')
extend('required', {
    ...required,
    message: 'Este campo es requerido.',
});
extend('url', {
    validate: value => {
        const regla = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}/gm;
        return regla.test(value) || 'URL inválido.';
    }
});

export default {
    name: "ParametrizacionTablero",
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            lastmodifiedby: '',
            editar: false,
            validarTablero: '',
            botonDisabled: false,
            buscar: {
                proceso: '',
                titulo: '',
            },
            crearTablero: {
                tituloTablero: '',
                descripcionTablero: '',
                urlTablero: '',
                proceso: '',
            },
            dialogoTablero: false,
            tituloDialogo: 'Crear tablero',
            procesos: [
                { text: 'Gestión Administrativa', value: 'A', role: 'Analitica_Administrativo'},
                { text: 'Gestión Comercial', value: 'C', role: 'Analitica_Comercial'},
                { text: 'Gestión del Talento Humano', value: 'T', role: 'Analitica_TalentoHumano'},
                { text: 'Gestión Farmacéutica', value: 'S', role: 'Analitica_ServiciosFarmaceuticos'},
                { text: 'Gestión Financiera', value: 'F', role: 'Analitica_Facturacion'},
                { text: 'Negociación, Compras y Abastecimiento', value: 'N', role: 'Analitica_Compras'},
                { text: 'Operación Logística', value: 'L', role: 'Analitica_Logistica'},
                { text: 'Prestación de Servicios de Salud', value: 'P', role: 'Analitica_ServiciosSalud'},
            ],
            tableroSeleccionado: {},
            tablaTableros: {
                items: [],
                loading: false,
                headers: [
                    { text: "PROCESO", align: "left", width: "15%", sortable: false },
                    { text: "TÍTULO", align: "left", width: "15%", sortable: false },
                    { text: "DESCRIPCIÓN", align: "left", width: "20%", sortable: false },
                    { text: "URL", align: "left", width: "30%", sortable: false },
                    { text: "ESTADO", align: "left", width: "10%", sortable: false },
                    { text: "ACCIONES", align: "center", width: "10%", sortable: false },
                ],
                footerProps: {
                    'items-per-page-options': [10, 20, 30],
                    'items-per-page-text': 'Items por página:',
                    'show-current-page': true,
                    'show-first-last-page': true
                },
                itemsPerPage: 10,
                page: 1,
                totalPage: 0,
            },
            dialogoCambiarEstado: false,
            mensajeEstado: '',
            userRoles: {},
            roles: Role,
        }
    },
    watch: {
        'buscar.proceso': function () {
            this.tablaTableros.page = 1;
            this.listarTableros();
        },
        'buscar.titulo': function () {
            this.tablaTableros.page = 1;
            this.listarTableros();
        },
        'tablaTableros.page': function () {
            this.listarTableros();
        },
        'tablaTableros.itemsPerPage': function () {
            this.tablaTableros.page = 1;
            this.listarTableros();
        },
    },
    computed: {
        ...mapState(["auth", "notify", "busy", "enterprise"]),
        /**
         * Filtrar los procesos disponibles basadas en los roles del usuario.
         */
        obtenerProcesos() {
            const rolesUsuario = this.auth.roles;
            return this.procesos.filter(proceso => rolesUsuario.includes(proceso.role));
        },
    },
    methods: {
        ...mapMutations([
            "updateAuth",
            "hideNotify",
            "showSuccess",
            "showBusy",
            "hideBusy",
        ]),
        /**
         * Realiza una búsqueda de tableros según los criterios especificados.
         * Actualiza la tabla de tableros con los resultados de la búsqueda.
         */
        listarTableros() {
            const rolesList = Object.values(this.auth.roles).map(role => role.toString()).join(',');
            this.tablaTableros.loading = true;
            this.$http.get(`msa-reports/parametrizacionTablero/buscar`, {
                params: {
                    page: this.tablaTableros.page - 1,
                    size: this.tablaTableros.itemsPerPage,
                    proceso: this.buscar.proceso,
                    tituloTablero: `${this.buscar.titulo.toLowerCase()}`,
                    idEmpresa: this.enterprise.code,
                    rol: rolesList
                }
            }).then((response) => {
                this.tablaTableros.items = response.data.content;
                this.tablaTableros.totalPage = response.data.totalElements;
                this.tablaTableros.loading = false;
            }).catch((error) => {
                console.error('Error al paginar los tableros:', error);
                this.tablaTableros.loading = false;
            })
        },
        /**
         * Devuelve el nombre del proceso correspondiente al valor especificado.
         * @param {*} item el valor del proceso.
         */
        obtenerNombreProceso(item) {
            const proceso = this.procesos.find(proceso => proceso.value === item);
            return proceso ? proceso.text : '';
        },
        /**
         * Valida la existencia de un tablero antes de crearlo.
         * Si el tablero ya existe, muestra un mensaje de error y deshabilita el botón de creación.
         */
        validarExistencia() {
            if (this.crearTablero.proceso && this.crearTablero.tituloTablero) {
                this.$http.get(`msa-reports/parametrizacionTablero/validarExistencia`, {
                    params: {
                        proceso: this.crearTablero.proceso,
                        tituloTablero: this.crearTablero.tituloTablero.trim(),
                        idEmpresa: this.enterprise.code
                    }
                }).then((response) => {
                    if (response.data !== null) {
                            this.validarTablero = "El tablero ya existe.";
                            this.botonDisabled = true;
                        } else {
                            this.validarTablero = "";
                            this.botonDisabled = false;
                        }
                }).catch((error) => {
                    console.log(error);
                })
            }
        },
        /**
         * Valida los caracteres ingresados en un evento de teclado.
         * Permite únicamente letras, números y espacios.
         * @param {*} event El evento de teclado.
         */
        validarCaracteres(event) {
            const key = event.key;
            if (!key.match(/[a-zA-ZáéíóúÁÉÍÓÚ0-9 ]/)) {
                event.preventDefault();
            }
        },
        /**
         * Agrega un nuevo tablero.
         */
        agregarTablero() {
            this.botonDisabled = true;
            const procesoSeleccionado = this.procesos.find(proceso => proceso.value === this.crearTablero.proceso);
            if (procesoSeleccionado && procesoSeleccionado.role) {
                const tablero = {
                    tituloTablero: this.crearTablero.tituloTablero.trim(),
                    descripcionTablero: this.crearTablero.descripcionTablero.trim(),
                    urlTablero: this.crearTablero.urlTablero.trim(),
                    proceso: this.crearTablero.proceso,
                    rol: procesoSeleccionado.role,
                    estado: false,
                    empresa: {
                        idEmpresa: this.enterprise.code
                    },
                    lastmodifiedby: this.lastmodifiedby
                };
                this.$http.post(`msa-reports/parametrizacionTablero/guardar`, tablero)
                .then(() => {
                    this.listarTableros();
                    this.dialogoTablero = false;
                    this.clear();
                }).catch((error) => {
                    console.error('Error al agregar el tablero:', error);
                    this.botonDisabled = true;
                })
            } else {
                console.error('Error: No se pudo encontrar el proceso seleccionado o no tiene un rol definido.');
            }
            
        },
        /**
         * Abre el diálogo para editar un tablero existente.
         * Copia los datos del tablero seleccionado en el formulario de edición.
         * @param {*} item - El objeto del tablero que se va a editar.
         */
        abrirDialogoEditar(item) {
            this.crearTablero = { ...item };
            this.editar = true;
            this.tableroSeleccionado = item;
            this.tituloDialogo = 'Editar tablero';
            this.dialogoTablero = true;
            this.$nextTick(() => {
                this.$refs.urlValidation.validate();
            })
        },
        /**
         * Edita un tablero existente en la lista de tableros.
         */
        editarTablero() {
            this.botonDisabled = true;
            const procesoSeleccionado = this.procesos.find(proceso => proceso.value === this.crearTablero.proceso);
            const tablero = {
                idTablero: this.tableroSeleccionado.idTablero,
                tituloTablero: this.crearTablero.tituloTablero.trim(),
                descripcionTablero: this.crearTablero.descripcionTablero.trim(),
                urlTablero: this.crearTablero.urlTablero.trim(),
                proceso: this.crearTablero.proceso,
                rol: procesoSeleccionado.role,
                estado: false,
                empresa: {
                    idEmpresa: this.enterprise.code
                },
                lastmodifiedby: this.lastmodifiedby
            }
            this.$http.put(`msa-reports/parametrizacionTablero/editar`, tablero)
            .then(() => {
                this.listarTableros();
                this.dialogoTablero = false;
                setTimeout(() => {
                    this.clear();
                }, 1500);
            }).catch((error) => {
                console.error('Error al editar el tablero:', error);
                this.botonDisabled = true;
            })
        },
        /**
         * Abre el diálogo para cambiar el estado (activar o inactivar) de un tablero.
         * @param {*} item - El objeto del tablero cuyo estado se va a cambiar.
         */
        abrirDialogoEstado(item) {
            this.dialogoCambiarEstado = true
            this.tableroSeleccionado = item;
            this.mensajeEstado = this.tableroSeleccionado.estado === false ? '¿Desea inactivar el tablero?' : '¿Desea activar el tablero?';
            setTimeout(() => {
                this.mensajeEstado = this.tableroSeleccionado.estado === false ? '¿Desea inactivar el tablero?' : '¿Desea activar el tablero?';
            }, 1000);
        },
        /**
         * Cambia el estado de un tablero y actualiza su estado en el servidor.
         */
        cambiarEstado() {
            this.tableroSeleccionado.estado = (this.tableroSeleccionado.estado === false) ? true : false;
            const tablero = {
                idTablero: this.tableroSeleccionado.idTablero,
                estado: this.tableroSeleccionado.estado,
                lastmodifiedby: this.lastmodifiedby
            }
            this.$http.put(`msa-reports/parametrizacionTablero/cambiarEstado`, tablero)
            .then(() => {
                this.listarTableros();
                this.dialogoCambiarEstado = false;
            }).catch((error) => {
                console.log(error);
            })
        },
        /**
         * Restablece el formulario de creación o edición de tablero a su estado inicial.
         */
        clear() {
            this.editar = false;
            this.botonDisabled = false;
            this.dialogoTablero = false;
            this.crearTablero.tituloTablero = '';
            this.crearTablero.descripcionTablero = '';
            this.crearTablero.urlTablero = '';
            this.crearTablero.proceso = '';
            this.$refs.observer.reset();
            this.tituloDialogo = 'Crear tablero';
            this.modulos = [];
            this.validarTablero = false;
        },
    },
    mounted() {
        this.listarTableros();
        this.lastmodifiedby = this.auth.username.toUpperCase();
    },
}
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                       ###### -->
<!-- #################################################################################### -->
<style scoped>
.contenido {
    padding: 1rem;
    width: 100%;
    height: 85vh;
}
.encabezado {
    background-color: #1867c0;
    color: white;
}
.campo {
    width: 100% !important;
}
::v-deep .elevation div table thead tr th {
    background-color: rgb(223, 223, 223) !important;
}
::v-deep .elevation div table thead tr th span {
    font-weight: bold;
    color: black !important;
}
.filtro {
    width: 50%
}
</style>